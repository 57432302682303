import React, { Component } from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Layout from 'src/components/layout';
import Disclaimer from '../../components/disclaimer';
import ConsumerPanel from '../../components/consumer-panel';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatedBlock } from 'src/components/language';
import { TranslatableText } from '../../components/language';

import PersonalResourcesImg from '../../images/faq-banner.png';
import DownloadImg from '../../images/download-icon.svg';
import AudioIcon from '../../images/audio-icon.svg';
import PatientCareGiver from '../../images/patientcaregiver.svg';
import ReactGA from 'react-ga4';

import { BannerComponent } from '../../components/banner-component'
import './index.scss';
import ConsultDoctor from '../../components/consult-doctor';
import ThingsToKnow from '../../components/things-to-know';
import SideEffects from '../../components/side-effects';

class PatientPage extends Component {
  static contextType = AppStateContext;
  state = {
    isAudioVisible: false,
  };
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('faq');
  }

  trackDownloads(category, action) {
    ReactGA.event({
      category,
      action,
    });
  }

  render() {
    const title = {
      english: 'FAQ',
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        
        <BannerComponent backgroundPhoto={PersonalResourcesImg} titleText="Frequently Asked Questions (FAQ)"/>

        <div className='container pt-4 text-center fw-bold px-2'>
          <p>These questions may help you get started with the conversation. Please note, any and all questions regarding VABYSMO should be discussed with your ophthalmologist.</p>
        </div>

        <div className="container mb-2 pb-2">
          <div className="firstAccordian">
            <h3 className="font-color-secondary-3">
              FAQs about wet AMD
              <br />{' '}
            </h3>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is wet AMD?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Wet AMD occurs when blood vessels grow abnormally in the eye causing blood and fluid to leak into the
                  macula, the part of the eye that controls sharp, straight-ahead vision.
                </p>
                <p>
                  Wet AMD is the leading cause of vision loss in people aged 60 and over, and can lead to severe vision
                  problems and even blindness if left untreated, but VABYSMO can help reduce the swelling and prevent
                  vision loss.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How does wet AMD affect my vision?</Accordion.Header>
              <Accordion.Body>
                <p>While wet AMD affects everyone differently, its symptoms may appear suddenly. They include:</p>

                <ul>
                  <li>Blurry or blind spots that appear in the central vision of the affected eye</li>
                  <li>Straight lines that appear wavy</li>
                  <li>Colours that appear less vibrant</li>
                  <li>Trouble seeing in low light.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What is “drying,” and why is it important?</Accordion.Header>
              <Accordion.Body>
                <p>
                  In wet AMD, blood and fluid can spill into the macula—the part of the eye that controls sharp,
                  straight-ahead vision.
                </p>
                <p>
                  The macula swells and leaks, leading to blurred vision and vision loss. VABYSMO can help quickly dry
                  the excess fluid.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>What happens if I stop eye injections for wet AMD?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Wet AMD is a chronic condition that requires long-term care. Keeping up with your treatment can
                  prevent blindness. Your Ophthalmologist can tell you more about what to expect from treatment with
                  VABYSMO.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div>
            <h3 className="font-color-secondary-3">
              <br />
              FAQs about VABYSMO for Wet AMD
              <br />{' '}
            </h3>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  VABYSMO is a prescription medicine administered by injection into the eye by your Ophthalmologist.
                </p>
                <p>
                  In New Zealand it is registered for the treatment of wet age-related macular degeneration (nAMD) and
                  diabetic macular oedema (DMO).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>What are the serious side effects of VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>VABYSMO may cause serious side effects, including:</p>
                <ul>
                  <li>
                    Pain, redness or blood around the coloured part of the eye bleeding into the eye causing a red tint
                    to your vision
                  </li>
                  <li>Blood or redness in the white part of the eye</li>
                  <li>
                    Increase in eye pressure – symptoms include severe headache, eye pain, nausea and vomiting, blurred
                    vision, eye redness
                  </li>
                  <li>scratch to the clear part at the front of the eye</li>
                  <li>
                    Inflammation of the gel-like substance at the back of the eye – symptoms include hazy vision or a
                    marked increase in floaters
                  </li>
                  <li>
                    Sudden onset of the following: eye pain, increased discomfort, worsening eye redness, a higher
                    number of floaters in your vision, or increased sensitivity to light – these are signs of a possible
                    eye infection or inflammation
                  </li>
                  <li>
                    Decrease or change in your vision which can be sudden or gradual, including dim vision, difficulty
                    with vision at night, fading sudden, brief flashes of light which is more likely to occur when you
                    move your eye, heavy feeling in the eye.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What are the common side effects of VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    The most common side effects with VABYSMO are cataract and blood on the white of the eye
                    (conjunctival haemorrhage).
                  </li>
                  <li>These are not all the possible side effects of VABYSMO.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>How many injections will I receive with VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  For people with wet AMD, VABYSMO is given every month for the first 4 months. Afterwards, your
                  Ophthalmologist will personalise your treatment schedule based on your needs, with 1 to 4 months
                  between injections during your first year of treatment.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>How fast does VABYSMO work?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Everyone is different, but studies show that VABYSMO may improve your vision after just 1 treatment.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>How long will I need to receive VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  Wet AMD is a chronic condition that requires long-term care. Your Ophthalmologist can tell you more
                  about what to expect from treatment with VABYSMO.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>What should I do if I experience a side effect?</Accordion.Header>
              <Accordion.Body>
                <p>Contact your Ophthalmologist right away if you think you might be experiencing any side effects.</p>

                <p>
                  {' '}
                  For questions about Roche medicines or services, to report an adverse event (side effect) or product
                  quality defect or to submit a temperature excursion assessment, please visit MedInfo.roche.com or
                  phone 0800 276 243. Please refer to the Consumer Medicine Information on the{' '}
                  <a href="https://www.medsafe.govt.nz/Consumers/CMI/v/vabysmo.pdf" className="links" title="Medsafe website">
                    Medsafe website
                  </a>{' '}
                  for product information applicable to New Zealand.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>What if I am already on a treatment for my wet AMD?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  If you are already on a vascular endothelial growth factor (VEGF) treatment, ask your Ophthalmologist
                  if switching to VABYSMO is right for you.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Does my insurance cover VABYSMO? What if I need help paying for it?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  VABYSMO is not a PHARMAC funded medicine, which means you will need to pay for and receive it
                  privately.
                </p>
                <p>
                  {' '}
                  The price of VABYSMO is NZ$1,799.75 (inclusive of gst) per vial. This represents the price of VABYSMO
                  only. Fees and charges associated with consultation, reconstitution, administration, shipping and
                  handling, and dispensing may apply. If you have health insurance, carefully check what is covered
                  under your policy – every health insurance provider has different benefits that cover various
                  treatments and procedures.
                </p>
                <p>
                  {' '}
                  Roche also offers support through the VABYSMO Cost Share Programme, which can assist with the cost of
                  your medicine. To find out more, ask your Ophthalmologist about the costs, benefits and safety
                  associated with VABYSMO.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div>
            <h3 className="font-color-secondary-3">
              <br />
              FAQs about Diabetic Macular Oedema (DMO)
              <br />{' '}
            </h3>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is DMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  DMO is a complication associated with diabetes. It occurs when consistently high blood sugar levels
                  damage the blood vessels in the eye causing fluid to leak into the macula, the part of the eye that
                  controls sharp, straight-ahead vision.
                </p>
                <p>
                  {' '}
                  If untreated, DMO can lead to severe vision problems and even blindness, but VABYSMO can help reduce
                  the swelling and prevent vision loss.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How does DMO affect my vision?</Accordion.Header>
              <Accordion.Body>
                <p> While DMO affects everyone differently, its symptoms include:</p>
                <ul>
                  <li>Blurry or blind spots appearing in the central vision of the affected eye</li>
                  <li>Straight lines that appear wavy</li>
                  <li>Colours that appear less vibrant</li>
                  <li>Trouble seeing in low light.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>What is "drying" and why is it important?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  In DMO, blood and fluid can spill into the macula—the part of the eye that controls sharp,
                  straight-ahead vision.
                </p>

                <p> The macula swells, leading to blurred vision and vision loss.</p>

                <p> VABYSMO can help quickly dry the excess fluid.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>What happens if I stop eye injections for DMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  It’s important to keep up with your treatment for DMO. Keeping up with your treatment can prevent
                  blindness. Your Ophthalmologist can tell you more about what to expect from treatment with VABYSMO.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div>
            <h3 className="font-color-secondary-3">
              <br />
              FAQs about VABYSMO for Diabetic Macular Oedema (DMO)
              <br />{' '}
            </h3>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  VABYSMO is a prescription medicine administered by injection into the eye by your Ophthalmologist.
                </p>
                <p>
                  {' '}
                  In New Zealand it is registered for the treatment of wet age-related macular degeneration (nAMD) and
                  diabetic macular oedema (DMO).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>What are the serious side effects of VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p> VABYSMO may cause serious side effects, including:</p>
                <ul>
                  <li>
                    Pain, redness or blood around the coloured part of the eye bleeding into the eye causing a red tint
                    to your vision
                  </li>
                  <li>Blood or redness in the white part of the eye</li>
                  <li>
                    Increase in eye pressure – symptoms include severe headache, eye pain, nausea and vomiting, blurred
                    vision, eye redness
                  </li>
                  <li>scratch to the clear part at the front of the eye</li>
                  <li>
                    Inflammation of the gel-like substance at the back of the eye – symptoms include hazy vision or a
                    marked increase in floaters
                  </li>
                  <li>
                    Sudden onset of the following: eye pain, increased discomfort, worsening eye redness, a higher
                    number of floaters in your vision, or increased sensitivity to light – these are signs of a possible
                    eye infection or inflammation
                  </li>
                  <li>
                    Decrease or change in your vision which can be sudden or gradual, including dim vision, difficulty
                    with vision at night, fading sudden, brief flashes of light which is more likely to occur when you
                    move your eye, heavy feeling in the eye.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>What are the common side effects of VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    The most common side effects with VABYSMO are cataract and blood on the white of the eye
                    (conjunctival haemorrhage)
                  </li>
                  <li>These are not all the possible side effects of VABYSMO.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>How many injections will I receive with VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  For people with DMO, VABYSMO is given every month for the first 4 or 6 months. Afterwards, your
                  Ophthalmologist will personalise your treatment schedule based on your needs, with the chance to
                  receive as few as 3 injections per year (after 4 or 6 initial monthly doses, receive a minimum of 6
                  injections over 2 years and a maximum of 21).
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>How fast does VABYSMO work?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  Everyone is different, but studies show that VABYSMO may improve your vision after just 1 treatment.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>How long will I need to receive VABYSMO?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  DMO is a chronic condition that requires long-term care. Your Ophthalmologist can tell you more about
                  what to expect from treatment with VABYSMO.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>What should I do if I experience a side effect?</Accordion.Header>
              <Accordion.Body>
                <p> Contact your Ophthalmologist right away if you think you might be experiencing any side effects.</p>

                <p>
                  {' '}
                  For questions about Roche medicines or services, to report an adverse event (side effect) or product
                  quality defect or to submit a temperature excursion assessment, please visit MedInfo.roche.com or
                  phone 0800 276 243. Please refer to the Consumer Medicine Information on the{' '}
                  <a href="https://www.medsafe.govt.nz/Consumers/CMI/v/vabysmo.pdf" className="links" title="Medsafe website">
                    Medsafe website
                  </a>{' '}
                  for product information applicable to New Zealand.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>What if I am already on a treatment for my wet AMD?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  If you are already on a vascular endothelial growth factor (VEGF) treatment, ask your Ophthalmologist
                  if switching to VABYSMO is right for you.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>Does my insurance cover VABYSMO? What if I need help paying for it?</Accordion.Header>
              <Accordion.Body>
                <p>
                  {' '}
                  VABYSMO is not a PHARMAC funded medicine, which means you will need to pay for and receive it
                  privately.
                </p>
                <p>
                  {' '}
                  The price of VABYSMO is NZ$1,799.75 (inclusive of gst) per vial. This represents the price of VABYSMO
                  only. Fees and charges associated with consultation, reconstitution, administration, shipping and
                  handling, and dispensing may apply.
                </p>
                <p>
                  {' '}
                  If you have health insurance, carefully check what is covered under your policy – every health
                  insurance provider has different benefits that cover various treatments and procedures.
                </p>
                <p>
                  {' '}
                  Roche also offers support through the VABYSMO Cost Share Programme, which can assist with the cost of
                  your medicine. To find out more, ask your Ophthalmologist about the costs, benefits and safety
                  associated with VABYSMO.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <ConsultDoctor bgColor="blue" />
        <Disclaimer />
        <ConsumerPanel />
      </Layout>
    );
  }
}

export default PatientPage;
